:root {
   --primary-color: #292929; 
   --secondary-color: #424242; 
}

.bg_secondary {
   background: var(--secondary-color);
}

html, body {
   height: 100%;
   height: 100%;
 }